import React, { useState } from "react";
import "./Faq.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusSquare,
  faMinusSquare,
} from "@fortawesome/free-regular-svg-icons";
function Faq() {
  const [open, setOpen] = useState([false, false, false, false]);
  const clickHandler = (e) => {
    const num = e.target.dataset.num;
    setOpen((prev) => {
      let arr = [...prev];
      let newArr = arr.map((elem, index) => {
        if (index == num) {
          return !elem;
        } else {
          return elem;
        }
      });
      return newArr;
    });
    console.log(open);
  };
  return (
    <div id="faq" className="faq">
      <h1 className="faq-h1">FAQ</h1>
      <div className="faq-div">
        <div className="faq-q-div">
          <div
            data-num={0}
            onClick={clickHandler}
            className={`faq-btn ${open[0] ? "border-radius" : ""}`}
          >
            <p className={`${open[0] ? "active-btn" : ""}`}>
              WHAT IS KEYBOARD WARRIORS?
            </p>
            {open[0] ? (
              <FontAwesomeIcon
                className={`${open[0] ? "active-btn" : ""}`}
                icon={faMinusSquare}
              />
            ) : (
              <FontAwesomeIcon icon={faPlusSquare} />
            )}
          </div>
          <div className={`faq-answer ${open[0] ? "active" : ""}`}>
            <p className={`answer ${open[0] ? "active-answer" : ""}`}>
            KEYBOARD WARRIORS IS AN NFT COLLECTION BUILT  ON THE CARDANO BLOCKCHAIN THAT BLENDS COLLECTABILITY WITH GAMING.
            EACH WARRIOR IS RANDOMLY GENERATED FROM 100'S OF DIFFERENT TRAITS INSPIRED BY FANTASY
            RPG VIDEO GAMES. AMONG THE COLLECTION ARE HIGHLY DETAILED ULTRA RARES,
            EACH WITH DISTINCT PERSONALITY AND ABILITIES USABLE IN A DISCORD CARD GAME
            </p>
            <br />
            <p className={`answer ${open[0] ? "active-answer" : ""}`}>
            </p>
          </div>
        </div>
        <div className="faq-q-div">
          <div
            data-num={1}
            onClick={clickHandler}
            className={`faq-btn ${open[1] ? "border-radius" : ""}`}
          >
            <p className={`${open[1] ? "active-btn" : ""}`}>
              WHAT IS THE MINT COST AND HOW DO I MINT?
            </p>
            {open[1] ? (
              <FontAwesomeIcon
                className={`${open[1] ? "active-btn" : ""}`}
                icon={faMinusSquare}
              />
            ) : (
              <FontAwesomeIcon icon={faPlusSquare} />
            )}
          </div>
          <div className={`faq-answer ${open[1] ? "active" : ""}`}>
            <p className={`answer ${open[1] ? "active-answer" : ""}`}>
              MINTING OF SEASON 1 HAS NOW CONCLUDED. YOU CAN ONLY PURCHASE SEASON 1 KEYBOARD WARRIORS
              ON THE SECONDARY MARKETPLACE
              <br></br>
              <br></br>
              <a href="https://www.jpg.store/collection/keyboardwarriors" target="_blank">JPG.STORE</a>
            </p>
          </div>
        </div>
        <div className="faq-q-div">
          <div
            data-num={2}
            onClick={clickHandler}
            className={`faq-btn ${open[2] ? "border-radius" : ""}`}
          >
            <p className={`${open[2] ? "active-btn" : ""}`}>
              WHAT ARE FACTIONS?
            </p>
            {open[2] ? (
              <FontAwesomeIcon
                className={`${open[2] ? "active-btn" : ""}`}
                icon={faMinusSquare}
              />
            ) : (
              <FontAwesomeIcon icon={faPlusSquare} />
            )}
          </div>
          <div className={`faq-answer ${open[2] ? "active" : ""}`}>
            <p className={`answer ${open[2] ? "active-answer" : ""}`}>
              YOU HAVE A CHOICE TO ALIGN YOURSELF WITH ONE OF TWO FACTIONS,
              ELYSIUM OR INFERNO. BY JOINING ELYSIUM YOU ALIGN YOURSELF WITH
              KINDNESS, AND DO GOODERY! YOU WOULD RATHER DEFUSE A FIGHT WITH
              LOGIC AND REASON THAN RESORT TO AGGRESSION. ELYSIUM PROVIDES LIGHT
              SIDE SPELL CARDS AND TITLES. WARRIORS WHO PREFER TO TAUNT AND TALK
              TRASH WILL BE ABLE TO JOIN INFERNO, PROVIDING YOU DARK SIDE SPELL
              CARDS AND TITLES.
            </p>
            <br />
            <p className={`answer ${open[2] ? "active-answer" : ""}`}>

            </p>
          </div>
        </div>
        <div className="faq-q-div">
          <div
            data-num={3}
            onClick={clickHandler}
            className={`faq-btn ${open[3] ? "border-radius" : ""}`}
          >
            <p className={`${open[3] ? "active-btn" : ""}`}>
              WHY SHOULD I MINT A KEYBOARD WARRIOR?
            </p>
            {open[3] ? (
              <FontAwesomeIcon
                className={`${open[3] ? "active-btn" : ""}`}
                icon={faMinusSquare}
              />
            ) : (
              <FontAwesomeIcon icon={faPlusSquare} />
            )}
          </div>
          <div className={`faq-answer ${open[3] ? "active" : ""}`}>
            <p className={`answer ${open[3] ? "active-answer" : ""}`}>
              WE HAVE A VERY CLEAR VISION ON HOW WE WANT TO COMBINE BLOCKCHAIN TECHNOLOGY AND GAMING IN A WAY THAT'S COMPELLING.
              YOU WILL HAVE DIRECT INFLUENCE ON HOW WE BUILD OUT THE EARLY STAGES OF OUR EXPERIENCE AND AS WE GROW YOU WILL
              BE ABLE TO GROW WITH US
            </p>
            <br />
            <p className={`answer ${open[3] ? "active-answer" : ""}`}>

            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
